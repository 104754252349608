// Generated by Framer (38f2e7f)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);
const PhosphorControls = getPropertyControls(Phosphor);

const enabledGestures = {ooIj49arr: {hover: true}};

const serializationHash = "framer-mKVSB"

const variantClassNames = {ooIj49arr: "framer-v-107wjah"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link, name1, width, ...props}) => { return {...props, a1LsqPOwg: name1 ?? props.a1LsqPOwg ?? "House", BTUNlSbHJ: link ?? props.BTUNlSbHJ} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;name1?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, a1LsqPOwg, BTUNlSbHJ, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "ooIj49arr", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={BTUNlSbHJ}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-107wjah", className, classNames)} framer-nvgu52`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ooIj49arr"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-dda86418-62d7-4975-8ae2-0326e59e9f2a, rgb(24, 24, 24))", borderBottomLeftRadius: 32, borderBottomRightRadius: 32, borderTopLeftRadius: 32, borderTopRightRadius: 32, ...style}} variants={{"ooIj49arr-hover": {backgroundColor: "var(--token-f6bc43b4-a35d-48d9-8f21-b0b1c50dca05, rgb(40, 40, 40))"}}} {...addPropertyOverrides({"ooIj49arr-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-4386kt-container"} layoutDependency={layoutDependency} layoutId={"X028VGkzS-container"}><Phosphor color={"var(--token-f31f1ce1-16c9-4281-87ba-360912934036, rgb(255, 255, 255))"} height={"100%"} iconSearch={"House"} iconSelection={a1LsqPOwg} id={"X028VGkzS"} layoutId={"X028VGkzS"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"regular"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-mKVSB.framer-nvgu52, .framer-mKVSB .framer-nvgu52 { display: block; }", ".framer-mKVSB.framer-107wjah { cursor: pointer; height: 32px; overflow: hidden; position: relative; text-decoration: none; width: 32px; will-change: var(--framer-will-change-override, transform); }", ".framer-mKVSB .framer-4386kt-container { flex: none; height: 18px; left: calc(50.00000000000002% - 18px / 2); position: absolute; top: calc(50.00000000000002% - 18px / 2); width: 18px; }", ".framer-mKVSB.framer-v-107wjah.hover.framer-107wjah { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 32px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"OGO8dsbEH":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"a1LsqPOwg":"name1","BTUNlSbHJ":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerjPG35ueSm: React.ComponentType<Props> = withCSS(Component, css, "framer-mKVSB") as typeof Component;
export default FramerjPG35ueSm;

FramerjPG35ueSm.displayName = "Component/Social Media Icon";

FramerjPG35ueSm.defaultProps = {height: 32, width: 32};

addPropertyControls(FramerjPG35ueSm, {a1LsqPOwg: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "House", description: undefined, hidden: undefined, title: "Name"}, BTUNlSbHJ: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerjPG35ueSm, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})